.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  height: 89px;
  padding: 20px 0;

  background-color: $color-default-white;
  border-bottom: 1px solid $border-secondary;

  @include vp--1599 {
    height: 126px;
  }

  @include vp--1023 {
    height: 76px;
  }
}

.header__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include vp--1599 {
    position: relative;
    display: block;
  }

  @include vp--1023 {
    display: flex;
  }
}

.header__logo {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 48px;

  margin-right: 95px;

  @include vp--1799 {
    margin-right: 60px;
  }

  @include vp--1599 {
    position: absolute;
    top: 0;
    left: 40px;
  }

  @include vp--1439 {
    position: absolute;
    top: 0;
    left: 32px;
  }

  @include vp--1023 {
    position: static;
    height: unset;
  }
}

.header__logo-img {
  width: 163px;
  height: 35px;
}

.header__nav {
  flex-grow: 1;

  @include vp--1023 {
    flex-grow: 0;
  }
}
