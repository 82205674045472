.summary__header {
  display: flex;

  margin-bottom: 40px;

  @include vp--1023 {
    display: block;
  }
}

.summary__title {
  flex-shrink: 0;

  width: 410px;
  max-width: 100%;
  margin: 0;
  margin-right: 25px;

  color: $site-primary;

  @include vp--1023 {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.summary-desc {
  max-width: 840px;
  margin: 0;
}

.summary__contacts {
  @include reset-list;

  display: flex;
  flex-wrap: wrap;

  margin-bottom: -20px;
}

.summary__contact {
  display: flex;
  width: 410px;
  margin-right: 25px;
  margin-bottom: 20px;
}

.summary__contact-icon {
  width: 25px;
  height: 25px;
  margin-top: 7px;
  margin-right: 10px;

  color: $txt-secondary;
}

.summary__contact-division {
  margin: 0;

  font-weight: 600;
}

.summary__contact-link {
  text-decoration: underline;
}
