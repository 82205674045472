.bread-crumbs {
  @include reset-list;

  display: flex;
  flex-wrap: wrap;
}

.bread-crumbs__item {
  padding-right: 26px;
}

.bread-crumbs__link {
  display: block;

  padding: 12px 0;

  .bread-crumbs__item:not(:last-child) & {
    position: relative;

    &::after {
      position: absolute;
      content: "";
      top: 50%;
      right: -13px;
      transform: translate(50%, -50%);

      width: 4px;
      height: 4px;

      background-color: $site-primary;
      border-radius: 50%;

      transition: background-color $trans--300;
    }
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    .bread-crumbs__item:not(:last-child) &:hover,
    .bread-crumbs__item:not(:last-child) &:focus {
      &::after {
        background-color: $site-secondary;
      }
    }
  }
}
