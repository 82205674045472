// Vendor
//---------------------------------

@import "vendor/normalize";
@import "vendor/swiper";

// Global
//---------------------------------

@import "variables";
@import "mixins";
@import "global/fonts";
@import "global/typography";
@import "global/reboot";
@import "global/utils";
@import "global/container";

// Blocks
//---------------------------------

@import "blocks/link";
@import "blocks/btn";
@import "blocks/header";
@import "blocks/main-nav";
@import "blocks/contact-links";
@import "blocks/bread-crumbs";
@import "blocks/section";
@import "blocks/content";
@import "blocks/page-header";
@import "blocks/hero";
@import "blocks/slider-hero";
@import "blocks/slider-btn";
@import "blocks/banner";
@import "blocks/summary";
@import "blocks/driving-directions";
@import "blocks/payment";
@import "blocks/categories";
@import "blocks/category-card";
@import "blocks/catalog";
@import "blocks/product-card";
@import "blocks/product";
@import "blocks/slider-product-gallery";
@import "blocks/slider-modal-photos";
@import "blocks/presentations";
@import "blocks/footer";
@import "blocks/modal";


// используйте это для отключения ховеров на тач устройствах
// ! но не используйте это для текстовых полей ( input, textarea )
// также для любых transition обязательно указывайте transition-property
// transition: color $default-transition-settings, а не transition: $default-transition-settings

/* @media (hover: hover), screen and (min-width: 0\0) {
  &:hover,
  &:focus {
    color: red;
  }
} */
