.banner {
  margin-bottom: 40px;

  @include vp--1023 {
    margin-bottom: 30px;
  }

  @include vp--599 {
    margin-bottom: 25px;
  }
}

.banner__link {
  display: block;
  position: relative;
}

.banner__bg {
  position: absolute;
  top: 28px;
  left: 0;
  right: 0;
  bottom: 28px;
  z-index: 1;

  overflow: hidden;

  background-color: $site-primary;
  border-radius: 12px;

  @include vp--1599 {
    top: 20px;
    bottom: 20px;
  }

  @include vp--1439 {
    top: 16px;
    bottom: 16px;
  }
}

.banner__bg-img {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.banner__content {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;

  min-height: 220px;
  padding: 0 145px;

  color: $color-default-white;

  @include vp--1599 {
    min-height: 190px;
    padding: 0 110px;
  }

  @include vp--1439 {
    min-height: 150px;
    padding: 0 55px;
  }

  @include vp--767 {
    min-height: 125px;
    padding: 0 25px;
  }

  @include vp--599 {
    min-height: 90px;
    padding: 0 16px;
  }
}

.banner__slogan {
  max-width: 650px;
  margin: 0;
  margin-right: 250px;
  padding: 60px 0;

  @include vp--1599 {
    margin-right: 190px;
    padding: 45px 0;
  }

  @include vp--1439 {
    margin-right: 75px;
    padding: 25px 0;
  }

  @include vp--767 {
    max-width: 450px;
    margin-right: 30px;
    padding: 20px 0;

    font-size: 32px;
  }

  @include vp--599 {
    max-width: 300px;
    margin-right: 20px;

    font-size: 22px;
  }

  @include vp--599 {
    font-size: 18px;
  }
}

.banner__photo {
  flex-shrink: 0;
  width: auto;
  max-width: 360px;
  height: 220px;

  @include vp--1599 {
    height: 190px;
  }

  @include vp--1439 {
    height: 145px;
  }

  @include vp--767 {
    height: 115px;
  }

  @include vp--599 {
    height: 70px;
  }
}

.banner__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
