.slider-product-gallery {
  display: flex;

  width: 100%;
  height: 602px;

  @include vp--1439 {
    height: 542px;
  }

  @include vp--599 {
    height: unset;
    flex-direction: column-reverse;
  }
}

.slider-product-gallery__thumbs {
  flex-shrink: 0;

  width: 92px;
  height: 100%;
  margin-right: 10px;

  @include vp--1439 {
    width: 82px;
  }

  @include vp--599 {
    width: 100%;
    height: unset;
    margin-top: 10px;
    margin-right: 0;
  }

  @include vp--599 {
    width: 100%;
    height: unset;
    margin-top: 7px;
    margin-right: 0;
  }

  @include vp--374 {
    width: 100%;
    height: unset;
    margin-top: 4px;
    margin-right: 0;
  }
}

.slider-product-gallery__thumbs-wrapper {
  @include vp--599 {
    height: 100%;
  }
}

.slider-product-gallery__thumbs-slide {
  cursor: pointer;
}

.slider-product-gallery__thumbs-slide-wrapper {
  overflow: hidden;

  border: 1px solid $border-primary;
  border-radius: 12px;

  transition: border-color $trans--300;

  &.swiper-slide-thumb-active {
    border-color: $site-primary;
  }

  @include vp--599 {
    position: relative;
    padding-top: 100%;
  }
}

.slider-product-gallery__thumbs-slide-img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  @include vp--599 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

}

.slider-product-gallery__btn {
  @include reset-btn;

  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  width: 40px;
  height: 40px;

  &--prev {
    top: -12px;
    transform: translateX(-50%) rotate(-180deg);
  }

  &--next {
    bottom: -12px;
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    top: 50%;

    width: 12px;
    height: 2px;
    background-color: $site-primary;

    transition: background-color $trans--300;
  }

  &::before {
    right: 50%;
    transform-origin: right bottom;
    transform: translateY(3px) rotate(45deg);
  }

  &::after {
    left: 50%;
    transform-origin: left bottom;
    transform: translateY(3px) rotate(-45deg);
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      &::before,
      &::after {
        background-color: $site-secondary;
      }
    }
  }

  @include vp--599 {
    top: 50%;
    transform: translateY(-50%);

    &--prev {
      left: -12px;
      transform: translateY(-50%) rotate(90deg);
    }

    &--next {
      left: unset;
      right: -12px;
      transform: translateY(-50%) rotate(-90deg);
    }
  }
}

.slider-product-gallery__photos {
  width: calc(100% - 100px);
  height: 100%;

  @include vp--599 {
    width: 100%;
    // height: 340px;
  }
}

.slider-product-gallery__photos-slide {
  overflow: hidden;

  border: 1px solid $border-primary;
  border-radius: 12px;

  .slider-product-gallery__slide-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
