.catalog__list {
  @include reset-list;

  display: grid;
  grid-template-columns: repeat(auto-fill, 415px);
  gap: 30px 20px;

  @include vp--1023 {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px 12px;
  }

  @include vp--599 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.catalog__load-more-btn {
  min-width: 210px;
  margin: 36px auto 0;
}
