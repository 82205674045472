.link {
  outline: none;

  transition: color $trans--300;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover[href],
    &:focus {
      color: $site-primary;
    }
  }

  &--accent {
    color: $site-primary;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover[href],
      &:focus {
        color: $site-secondary;
      }
    }
  }
}

// button.link:not(:disabled):hover {
//   color: $color-flory;
// }
