
// headers
// -------------------------------

h1, .h1 {
  font-weight: 400;
  font-size: 42px;
  line-height: 1.1;
  text-transform: uppercase;

  @include vp--599 {
    font-size: 32px;
  }
}

h2, .h2 {
  font-weight: 400;
  font-size: 42px;
  line-height: 1.1;
  text-transform: uppercase;

  @include vp--599 {
    font-size: 32px;
  }
}

h3, .h3 {
  font-weight: 400;
  font-size: 32px;
  line-height: 1.1;
  text-transform: uppercase;

  @include vp--599 {
    font-size: 24px;
  }
}

h4, .h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.5;

  @include vp--599 {
    font-size: 18px;
  }
}

h5, .h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.3;

  @include vp--599 {
    font-size: 16px;
  }
}

h6, .h6 {
  font-weight:16px;
  line-height: 1.3;
}


// paragraphs
// -------------------------------

.text-lg {
  font-weight: 300;
  font-size: 20px;
  line-height: 1.5;

  @include vp--599 {
    font-size: 16px;
  }
}

.text-md {
  font-weight: 300;
  font-size: 18px;
  line-height: 1.3;

  @include vp--599 {
    font-size: 16px;
  }
}

.text-sm {
  font-weight: 300;
  font-size: 14px;
  line-height: 1.3;

  @include vp--599 {
    font-size: 12px;
  }
}
