.category-card {
  overflow: hidden;
  display: block;
  position: relative;

  min-height: 240px;
  height: 100%;

  background-color: $color-whisper;
  border-radius: 12px;

  @include vp--599 {
    min-height: 140px;
  }
}

.category-card__title {
  margin: 0;
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 200px;
  padding-bottom: 18px;

  font-size: 30px;

  background-color: $bg-secondary;

  transition:
    color $trans--300,
    background-color $trans--300;

  .category-card--reverted & {
    padding-left: 210px;
    padding-right: 40px;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    .category-card:hover &,
    .category-card:focus & {
      color: $color-default-white;
      background-color: $site-primary;
    }
  }

  @include vp--599 {
    padding: 16px;
    padding-right: 90px;
    padding-bottom: 12px;

    font-size: 22px;

    .category-card--reverted & {
      padding: 16px;
      padding-left: 80px;
      padding-bottom: 12px;
    }
  }

  @include vp--374 {
    font-size: 20px;
  }
}

.category-card__desc {
  margin: 0;
  padding-top: 18px;
  padding-left: 40px;
  padding-right: 220px;
  padding-bottom: 24px;

  .category-card--reverted & {
    padding-left: 210px;
    padding-right: 40px;
  }

  @include vp--599 {
    padding: 16px;
    padding-top: 12px;
    padding-right: 90px;

    .category-card--reverted & {
      padding: 16px;
      padding-top: 12px;
      padding-left: 80px;
    }
  }
}

.category-card__cover {
  position: absolute;
  right: 0;
  bottom: 0;

  .category-card--reverted & {
    right: unset;
    left: 0;
  }
}

.category-card__img {
  width: auto;
  height: 240px;

  @include vp--599 {
    height: 90px;
  }
}
