// Desktop first

@mixin vp--1799 {
  @media (max-width: $vp--1800 - 1) {
    @content;
  }
}

@mixin vp--1599 {
  @media (max-width: $vp--1600 - 1) {
    @content;
  }
}

@mixin vp--1439 {
  @media (max-width: $vp--1440 - 1) {
    @content;
  }
}

@mixin vp--1023 {
  @media (max-width: $vp--1024 - 1) {
    @content;
  }
}

@mixin vp--767 {
  @media (max-width: $vp--768 - 1) {
    @content;
  }
}

@mixin vp--599 {
  @media (max-width: $vp--600 - 1) {
    @content;
  }
}

@mixin vp--374 {
  @media (max-width: $vp--375 - 1) {
    @content;
  }
}

@mixin reset-btn {
  padding: 0;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  background-color: $color-transparent;
  border: none;
  outline: none;

  &:not(:disabled) {
    cursor: pointer;
  }
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
