.categories__list {
  @include reset-list;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(490px, 1fr));
  gap: 20px;
  justify-content: center;

  @include vp--1439 {
    grid-template-columns: repeat(auto-fit, minmax(460px, 1fr));
  }

  @include vp--1023 {
    grid-template-columns: minmax(460px, 640px);
  }

  @include vp--599 {
    grid-template-columns: minmax(280px, 400px);
  }
}
