.content {
  margin: 50px 0;

  @include vp--1023 {
    margin: 40px 0;
  }

  @include vp--599 {
    margin: 30px 0;
  }
}

.content__cols {
  column-count: 2;
  column-gap: 55px;

  @include vp--1023 {
    column-count: unset;
  }
}

.content__paragraph {
  break-inside: avoid-column;
  margin: 0;
  margin-bottom: 16px;
}
