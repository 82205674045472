.product__details {
  display: flex;

  @include vp--1023 {
    display: block;
  }
}

.product__photo-gallery {
  flex-shrink: 0;

  width: 704px;
  margin-right: 36px;

  @include vp--1439 {
    width: 524px;
    margin-right: 24px;
  }

  @include vp--1023 {
    width: 100%;
    max-width: 740px;
    margin-bottom: 32px;
  }
}

.product__info-wrapper {
  display: flex;
  flex-direction: column;

  max-width: 740px;
  min-height: 602px;

  @include vp--1023 {
    min-height: unset;
  }
}

.product__info {
  margin-bottom: 32px;
  padding-bottom: 32px;

  border-bottom: 1px solid $border-secondary;

  @include vp--1439 {
    margin-bottom: 24px;
    padding-bottom: 24px;
  }

  @include vp--1023 {
    margin-bottom: 32px;
    padding-bottom: 32px;
  }
}

.text-md.product__lead {
  max-width: 420px;
  margin: 0;
  margin-bottom: 20px;
}

.product-card__id {
  margin: 0;
  margin-bottom: 20px;
  color: $color-grey;
}

.pruduct__features-title {
  margin: 0;
  margin-bottom: 32px;

  @include vp--1439 {
    margin-bottom: 24px;
  }
}

.product__features {
  @include reset-list;

  margin-bottom: 32px;

  @include vp--1439 {
    margin-bottom: 24px;
  }
}

.product__feature {
  position: relative;
  padding-left: 28px;

  &::before {
    position: absolute;
    content: "";
    top: 50%;
    left: 14px;
    transform: translate(-50%, -50%);

    width: 6px;
    height: 6px;

    border: 1px solid $site-primary;
    border-radius: 50%;
  }
}

.link--accent.product__documentation-link {
  display: flex;

  font-weight: 600;
  font-size: 18px;
  line-height: percentage(22 / 18);

  text-decoration: underline;
}

.link--accent.product__documentation-link-icon {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}

.product__add-to-cart-btn {
  margin-top: auto;
}
