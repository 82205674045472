.section {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 100px;
  }

  @include vp--1023 {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 75px;
    }
  }

  @include vp--599 {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 60px;
    }
  }
}
