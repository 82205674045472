.hero {
  position: relative;

  padding: 0;

  color: $color-default-white;
}

.hero__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: $color-nero;
}

.hero__bg-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
