.product-card {
  position: relative;

  height: 100%;
}

.product-card__link {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  height: 100%;
  padding: 30px;

  border: 1px solid $border-primary;
  border-radius: 12px;

  transition: border-color $trans--300;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      border-color: $site-primary;
    }
  }

  @include vp--1023 {
    padding: 24px 16px;
  }

  @include vp--767 {
    padding-bottom: 90px;
  }

  @include vp--599 {
    padding-bottom: 25px;
  }

  @include vp--374 {
    padding-bottom: 90px;
  }
}

.product-card__cover {
  position: relative;

  width: 100%;
  height: 222px;
  margin-bottom: 36px;

  @include vp--374 {
    height: 160px;
  }
}

.product-card__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-card__title {
  margin: 0;
  margin-bottom: 20px;

  font-size: 30px;
  color: $site-primary;

  @include vp--599 {
    font-size: 22px;
  }
}

.product-card__lead {
  margin: 0;
  margin-bottom: 20px;
}

.text-md.product-card__id {
  margin: 0;
  margin-bottom: 20px;
  color: $color-grey;
}

.product-card__details-btn {
  pointer-events: none;
  margin: 0;
  margin-top: auto;

  @media (hover: hover), screen and (min-width: 0\0) {
    .product-card__link[href]:hover  &,
    .product-card__link:focus  & {
      background-color: $site-secondary;
      border-color: $site-secondary;
    }
  }
}

.product-card__add-to-cart-btn {
  position: absolute;
  right: 30px;
  bottom: 30px;

  @include vp--1023 {
    right: 16px;
    bottom: 24px;
  }

  @include vp--767 {
    left: 15px;
    right: unset;
  }

  @include vp--599 {
    left: unset;
    right: 15px;
  }

  @include vp--374 {
    left: 15px;
    right: unset;
  }
}
