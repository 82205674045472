@font-face {
  font-style: normal;
  font-weight: 300;
  font-family: "Proxima Nova";

  font-display: swap;
  src:
    url("../fonts/proximanova-light.woff2") format("woff2"),
    url("../fonts/proximanova-light.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Proxima Nova";

  font-display: swap;
  src:
    url("../fonts/proximanova-regular.woff2") format("woff2"),
    url("../fonts/proximanova-regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "Proxima Nova";

  font-display: swap;
  src:
    url("../fonts/proximanova-semibold.woff2") format("woff2"),
    url("../fonts/proximanova-semibold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Proxima Nova";

  font-display: swap;
  src:
    url("../fonts/proximanova-bold.woff2") format("woff2"),
    url("../fonts/proximanova-bold.woff") format("woff");
}
