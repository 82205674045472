.footer {
  padding: 50px 0 65px;

  border-top: 1px solid $border-primary;

  @include vp--767 {
    padding: 32px 0 40px;
  }
}

.footer__container {
  display: flex;

  @include vp--1599 {
    display: block;
  }
}

.foter__logo-and-contacts {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  width: 300px;
  margin-right: 60px;

  @include vp--1599 {
    flex-direction: row;

    width: unset;
    margin-right: 0;
    margin-bottom: 48px;
  }

  @include vp--599 {
    display: block;
  }
}

.foter__logo {
  margin-bottom: 16px;

  @include vp--1599 {
    width: 240px;
    margin-right: 360px;
    margin-bottom: 0;
  }

  @include vp--1023 {
    margin-right: 60px;
  }

  @include vp--599 {
    width: unset;
    margin-right: 0;
    margin-bottom: 32px;
  }
}

.foter__logo-img {
  width: 70px;
  height: 15px;
}

.footer__copyright {
  margin: 0;
  margin-top: 16px;
}

.footer__contact-links {
  margin-top: auto;

  @include vp--1599 {
    margin-top: 0;
  }
}

.footer__menu {
  @include reset-list;

  display: flex;
  margin-right: 60px;

  @include vp--1599 {
    margin-right: 0;
    margin-bottom: 32px;
  }

  @include vp--599 {
    display: block;
  }
}

.footer__menu-group {
  &:not(:last-child) {
    margin-right: 60px;
  }

  @include vp--599 {
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 32px;
    }
  }
}

.footer__menu-title {
  margin: 0;
  margin-bottom: 32px;

  @include vp--1599 {
    margin-bottom: 24px;
  }

  @include vp--1023 {
    margin-bottom: 16px;
  }
}

.footer__menu-list {
  @include reset-list;

  margin-bottom: -5px;

  &--catalog {
    columns: 2;
    column-gap: 60px;
  }

  @include vp--1023 {
    margin-bottom: 0;

    &--catalog {
      columns: unset;
    }
  }
}

.footer__menu-item {
  width: 240px;
  margin-bottom: 5px;

  @include vp--1023 {
    margin-bottom: 0;
  }
}

.footer__menu-link {
  &--active {
    color: $txt-secondary;
  }

  @include vp--1023 {
    display: block;

    width: fit-content;
    padding: 5px 0;
  }
}

.footer__developer {
  align-self: flex-end;

  margin: 0;
  margin-left: auto;
}

.footer__developer-link {
  text-decoration: underline;
}
