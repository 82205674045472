.slider-modal-photos {
  position: relative;

  width: 100%;
  height: 100%;
}

.slider-modal-photos__container {
  width: 100%;
  height: 100%;
}

.slider-modal-photos__photo {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.slider-modal-photos__btn {
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;

  &.slider-btn--prev {
    left: -25px;
  }

  &.slider-btn--next {
    right: -25px;
  }

  @include vp--767 {
    display: none;
  }
}

.slider-modal-photos__pagination {
  display: flex;
  justify-content: center;
  position: absolute;

  &.swiper-pagination-horizontal {
    bottom: -15px;
  }
}

.slider-modal-photos__bullet {
  width: 5px;
  height: 5px;

  background-color: $bg-secondary;
  border-radius: 2px;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.slider-modal-photos__active-bullet {
  background-color: $site-primary;
}
