.btn {
  @include reset-btn;

  min-width: 152px;
  width: max-content;
  padding: 9px 18px;

  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  text-align: left;

  color: $color-default-white;

  background-color: $site-primary;
  border: 1px solid $site-primary;
  border-radius: 4px 22px 22px 4px;

  transition:
    color $trans--300,
    background-color $trans--300,
    border-color $trans--300;

  &:disabled {
    opacity: 0.5;
  }

  &--size-sm {
    min-width: 130px;
    padding: 9px 18px;

    font-size: 16px;
    line-height: 1.25;

    border-radius: 3px 19px 19px 3px;
  }

  &--secondary {
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: unset;
    padding: 10px 25px;
    font-size: 18px;
    line-height: percentage(22 / 18);
    text-decoration: underline;

    color: $site-primary;
    background-color: $bg-primary;
    border: 1px solid $site-primary;
    border-radius: 22px;

    .btn__icon {
      width: 23px;
      height: 18px;
      margin-left: -5px;
      margin-right: 8px;

      color: $txt-secondary;
    }
  }
}

.btn__icon {
  transition: color $trans--300;
}

@media (hover: hover), screen and (min-width: 0\0) {
  button.btn:not(:disabled):hover,
  a.btn[href]:hover,
  .btn:focus {
    background-color: $site-secondary;
    border-color: $site-secondary;
  }
  button.btn--secondary:not(:disabled):hover,
  a.btn--secondary[href]:hover,
  .btn--secondary:focus {
    color: $color-default-white;

    .btn__icon {
      color: $color-default-white;
    }
  }
}

a.btn {
  display: inline-block;

  &:not([href]) {
    cursor: auto;
  }
}
