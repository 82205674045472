.main-nav__toggle {
  @include reset-btn;

  display: none;
  position: relative;

  width: 35px;
  height: 35px;

  @include vp--1023 {
    display: block;
  }
}

.main-nav__toggle-line {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;

  width: 100%;
  height: 5px;

  background-color: $site-primary;

  transition:
    transform $trans--300,
    width $trans--300,
    background-color $trans--300;

  &--top {
    transform: translate(-50%, calc(-50% - 10px));
  }

  &--center {
    transform: translate(-50%, -50%);
  }

  &--bottom {
    transform: translate(-50%, calc(-50% + 10px));
  }

  .main-nav.is-open & {
    &--top {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &--center {
      transform: translate(-100%, -50%);

      width: 0;
    }

    &--bottom {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    .main-nav__toggle:hover &,
    .main-nav__toggle:focus & {
      background-color: $site-secondary;
    }
  }
}

.main-nav__overlay {
  @include vp--1023 {
    position: fixed;
    top: 75px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;

    overflow: hidden;
    visibility: hidden;
    opacity: 0;

    background-color: $color-default-black-040;

    transition:
      overflow $trans--300,
      visibility $trans--300,
      opacity $trans--300;

    .main-nav.is-open & {
      overflow: visible;
      visibility: visible;
      opacity: 1;
    }
  }
}

.main-nav__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include vp--1599 {
    flex-direction: column-reverse
  }

  @include vp--1023 {
    flex-direction: column;
    justify-content: unset;

    position: fixed;
    top: 75px;
    left: 100%;
    bottom: 0;
    z-index: 11;

    overflow-y: auto;

    min-width: 275px;
    padding: 16px 24px 32px;

    background-color: $color-default-white;
    border-top: 1px solid $border-secondary;

    transition: transform $trans--300;

    .main-nav.is-open & {
      transform: translateX(-100%)
    }
  }
}

.main-nav__list {
  @include reset-list;

  align-self: center;
  display: flex;
  flex-wrap: wrap;

  margin: -5px -15px;

  @include vp--1023 {
    align-self: unset;
    display: block;

    margin: 0;
    margin-bottom: 32px;
  }
}

.main-nav__item {
  flex-shrink: 0;

  padding: 5px 15px;

  @include vp--1023 {
    padding: 0;
  }
}

.main-nav__link {
  display: block;
  width: fit-content;
  font-weight: 600;
  text-transform: uppercase;

  &--active {
    color: $txt-secondary;
  }

  @include vp--1023 {
    padding: 8px 0;
  }
}

.main-nav__inner {
  flex-shrink: 0;
  display: flex;
  align-items: center;

  @include vp--1599 {
    align-self: flex-end;
    margin-bottom: 20px;
    padding-left: 230px;
  }

  @include vp--1023 {
    align-self: unset;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;
    margin-bottom: 0;
    padding-left: 0;
  }
}

.main-nav__cart-link {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 95px;

  @include vp--1799 {
    margin-left: 60px;
  }

  @include vp--1023 {
    margin-left: 16px;
    margin-bottom: 32px;
  }
}

.main-nav__cart-icon {
  width: 27px;
  height: 21px;
  margin-right: 10px;

  color: $txt-secondary;
}

.main-nav__cart-title {
  margin: 0;

  font-weight: 600;
}

.main-nav__cart-data {
  margin: 0;

  font-weight: 400;
  color: $site-primary;

  transition: color $trans--300;

  @media (hover: hover), screen and (min-width: 0\0) {
    .main-nav__cart-link:hover &,
    .main-nav__cart-link:focus & {
      color: $site-secondary;
    }
  }
}

.main-nav__contact-links {
  flex-shrink: 0;
  margin-left: 95px;

  @include vp--1799 {
    margin-left: 60px;
  }

  @include vp--1023 {
    margin-left: 16px;
  }
}
