.slider-btn {
  @include reset-btn;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 38px;
  height: 38px;

  color: $site-secondary;

  background-color: $color-default-white;
  border-radius: 50%;

  transition: color $trans--300;

  &--light {
    color: $color-default-white;

    background-color: $color-transparent;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $site-primary;
    }
  }
}

.slider-btn__inner {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border: 2px solid $site-secondary;
  border-radius: 50px;

  transition: border-color $trans--300;

  .slider-btn--light & {
    border-color: $color-default-white;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    .slider-btn:hover &,
    .slider-btn:focus & {
      border-color: $site-primary;
    }
  }
}

.slider-btn__icon {
  .slider-btn--next & {
    transform: rotate(180deg);
  }
}
