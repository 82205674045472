.slider-hero {
  position: relative;
}

.slider-hero__container {
  overflow: unset;
}

.slider-hero__slide {
  min-height: 340px;
}

.slider-hero__slide-inner {
  position: relative;
  display: flex;
}

.slider-hero__details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  max-width: 735px;
  margin-right: 150px;
  padding: 45px 0 35px;

  @include vp--1439 {
    margin-right: 95px;
  }

  @include vp--1023 {
    margin-right: 55px;
  }
}

.slider-hero__title {
  margin: 0;

  font-size: 44px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @include vp--599 {
    font-size: 34px;
  }

  @include vp--374 {
    font-size: 28px;
  }

  strong {
    font-weight: inherit;
    color: $site-primary;
  }
}

.slider-hero__desc {
  max-width: 550px;
  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.slider-hero__paragraph {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.slider-hero__btn {
  margin-top: auto;
}

.slider-hero__photo {
  flex-shrink: 0;

  max-width: 550px;
  max-height: 550px;

  @include vp--1439 {
    max-width: 400px;
    max-height: 400px;
  }

  @include vp--1023 {
    max-width: 240px;
  }

  @include vp--767 {
    display: none;
  }
}

.slider-hero__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.slider-hero__controls {
  display: flex;
  align-items: center;
  height: 80px;

  background-color: $color-default-white-090;
}

.slider-hero__controls-inner {
  display: flex;
  align-items: center;
}

.slider-hero__pagination {
  width: max-content;
  min-width: 56px;
  padding: 0 8px;

  font-size: 16px;
  line-height: 1.1;
  text-align: center;

  color: $color-default-black;
}
