.page-header {
  margin-bottom: 30px;
  padding-top: 12px;

  @include vp--1023 {
    margin-bottom: 25px;
  }
}

.page-header__title {
  margin: 0;
}
