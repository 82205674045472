.payment__title {
  margin: 0;
  margin-bottom: 15px;
}

.payment__list {
  @include reset-list;

  column-count: 2;
  column-gap: 135px;

  width: max-content;
  max-width: 100%;

  @include vp--1439 {
    column-gap: 65px;
  }

  @include vp--1023 {
    column-count: unset;
  }
}

.payment__item {
  break-inside: avoid-column;

  max-width: 590px;
}

.payment__record {
  display: inline;

  color: $color-grey;
}

.payment__value {
  display: inline;

  margin-left: 0;
}
