.presentations {
  position: relative;

  padding-bottom: 90px;

  color: $color-default-white;
}

.presentations__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: $color-nero;
}

.presentations__bg-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.presentation__wrapper {
  position: relative;
  z-index: 1;
}

.presentation__content {
  display: flex;

  @include vp--1023 {
    display: block;
  }
}

.presentation__title {
  flex-shrink: 0;

  margin: 0;
  width: 520px;
  margin-right: 150px;

  strong {
    font-weight: inherit;
    color: $site-primary;
  }

  @include vp--1439 {
    width: 440px;
    margin-right: 110px;
  }

  @include vp--1023 {
    width: unset;
    max-width: 520px;
    margin-right: 0;
    margin-bottom: 50px;
  }
}

.presentation__paper-lists {
  @include reset-list;

  display: flex;
  flex-wrap: wrap;

  margin-bottom: -50px;
}

.presentation__paper-list {
  max-width: 405px;
  padding-right: 40px;
  padding-bottom: 50px;
}

.presentation__paper-list-title {
  margin: 0;
  margin-bottom: 8px;

  text-transform: unset;
}

.presentation__papers {
  @include reset-list;
}

.presentation__paper {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.presentation__paper-link {
  text-decoration: underline;
}
