.driving-directions__list {
  @include reset-list;
}

.driving-directions__item {
  border-bottom: 1px solid $border-secondary;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  @include vp--1023 {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

.driving-directions__title {
  margin: 0;
  margin-bottom: 20px;
}

.driving-directions__inner {
  display: flex;

  margin-bottom: 40px;

  @include vp--1023 {
    display: block;

    margin-bottom: 30px;
  }
}

.driving-directions__map {
  position: relative;

  width: 994px;
  height: 300px;
  margin-right: 25px;

  @include vp--1023 {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.driving-directions__map-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.driving-directions__map-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  border: none;
}

.driving-directions__desc-wrapper {
  flex-shrink: 0;
  width: 700px;

  @include vp--1439 {
    width: 450px;
  }

  @include vp--1023 {
    width: 100%;
  }
}

.driving-directions__desc {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &--hint {
    font-weight: 600;

    color: $site-primary;
  }

  strong {
    font-weight: 700;
  }
}
