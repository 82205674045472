.contact-links {
  display: flex;
  flex-direction: column;

  &--main-nav {
    align-items: flex-end;
  }

  @include vp--1023 {
    &--main-nav {
      align-items: flex-start;
    }
  }
}

.contact-links__phone-link {
  width: max-content;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.22;

  @include vp--1023 {
    padding: 5px 0;
  }
}

.contact-links__email-link {
  display: flex;
  align-items: center;

  width: max-content;

  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;
  text-decoration: underline;

  @include vp--1023 {
    padding: 5px 0;
  }
}

.contact-links__email-icon {
  display: none;

  width: 12px;
  height: 12px;
  margin-right: 5px;

  color: $txt-secondary;

  .contact-links--main-nav & {
    display: block;
  }
}
